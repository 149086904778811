.contact-box {
  background-color: #212221;
}

.contact-container {
  min-height: 100vh !important;
}

.thankyou-container {
  min-height: 100vh !important;
}

.image {
  min-height: 30px !important;
}

.form-control {
  border: 1px solid #212221 !important;
}

.form-button {
  border: 1px solid black !important;
}

@media only screen and (max-width: 768px) {
  .margin-top-mobile {
    margin-top: 2em !important;
  }
}
