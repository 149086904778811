.nav-link {
  color: black !important;
  font-size: 14pt !important;
}

.nav-link:hover {
  color: #ca2128 !important;
}

@media only screen and (max-width: 425px) {
  .navbar-brand {
    width: 75% !important;
  }
}
