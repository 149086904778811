* {
  font-family: "Montserrat", sans-serif;
}

a {
  text-decoration: none !important;
}

.white {
  color: white !important;
}

.black {
  color: black !important;
}

.red {
  color: #ca2128 !important;
}

.bold {
  font-weight: 900 !important;
}

@media only screen and (max-width: 768px) {
  .margin-top-mobile {
    margin-top: 1em !important;
  }
}