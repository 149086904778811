.hp-main-container {
  padding-left: unset !important;
  padding-right: unset !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center !important;
  min-height: 70vh !important;
}

.hp-main-text {
  font-size: 50pt !important;
}

@media only screen and (max-width: 768px) {
  .hp-main-text {
    font-size: 30pt !important;
  }

  .hp-main-undertext {
    font-size: 12pt !important;
  }
}
